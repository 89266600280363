// booleanSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: "en",
};

const booleanSlice = createSlice({
  name: 'boolean',
  initialState,
  reducers: {
    // toggle: (state) => {
    //   state.language = !state.isToggled;
    // },
    setEnglish: (state) => {
      state.language = "en";
    },
    setHindi: (state) => {
      state.language = "hi";
    },
  },
});

export const {  setEnglish, setHindi } = booleanSlice.actions;

export default booleanSlice.reducer;
