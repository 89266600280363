import { AppRoutes, routesArr } from "./routes";
import Header from './components/Header'
import Footer from './components/Footer'
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import './style.css'

function App() {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if(location.pathname === '/') {
      navigate(routesArr.HOMEPAGE.path)
    }
  }, [])

  return (
    <div>
      <Header />
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;
