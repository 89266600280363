import { createUseStyles } from "react-jss";
import { IconButton, Typography } from "@mui/material";
import {MailOutline } from "@mui/icons-material";

const useStyles = createUseStyles(() => ({
  container: {
    padding: "15px 80px",
    paddingBottom:"0",
    display: "flex",
    alignItems: "center",
    justifyContent:"space-between",
    "@media (min-width: 320px)": {
      padding: "10px 20px",
    },
    "@media (min-width: 374px)": {
      // transform: "translate(-10rem) rotate(180deg)",
    },
    "@media (min-width: 1200px)": {
      padding: "15px 80px",
    },
  },
  thirdInner: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    width: "150px",
    fontFamily: 'Poppins, sans-serif',
  },
  secondInner: {
    flex: 1,
    display: "flex",
    fontFamily: 'Poppins, sans-serif',
  },
  textFamily:{
    fontFamily: 'Poppins, sans-serif',
    "@media (min-width: 320px)": {
      fontSize:"12px"
    },
    "@media (min-width: 374px)": {
      // transform: "translate(-10rem) rotate(180deg)",
    },
    "@media (min-width: 1200px)": {
      fontSize:"16px"

    },
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.secondInner}>
        <Typography className={classes.textFamily}>ALL RIGHTS RESERVED BY GOVERNMENT OF INDIA @ 2023 | DISCLAIMER</Typography>
      </div>
      <div className={classes.textFamily}>
        <Typography className={classes.textFamily}>
          <IconButton>
            <MailOutline />
          </IconButton>
          CONTACT
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
