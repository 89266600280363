import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

const Home = lazy(() => import('./views/Home'));
const HomeLogo = lazy(() => import('./views/HomeLogo'));
const MenuScreen = lazy(() => import('./components/MenuScreen'));

const pre = '';
// const pre = '/prerna';

export const routesArr = {
	HOMEPAGE: {
		path: `${pre}/home`,
		name: 'HOMEPAGE',
		component: HomeLogo,
		id: uuid(),
	},
	HOMEPAGELANG: {
		path: `${pre}/home/:langCode`,
		name: 'HOMEPAGE',
		component: HomeLogo,
		id: uuid(),
	},
	MENULIST: {
		path: `${pre}/menu/:menuPath`,
		name: 'MENULIST',
		component: MenuScreen,
		id: uuid(),
	},
	MENULISTLANG: {
		path: `${pre}/menu/:menuPath/:langCode`,
		name: 'MENULIST',
		component: MenuScreen,
		id: uuid(),
	},
	
	CONTENT: {
		path: `${pre}/content/:pageCategory/:pageSubCategory/:langCode`,
		// path: `${pre}/content/:pageCategory/:langCode`,
		name: 'CONTENT',
		component: Home,
		id: uuid(),
	},
	
};

export function AppRoutes() {
	return (
		<Suspense>
			<Routes>
				{Object.values(routesArr).map((routeItem) => (
					<Route
						path={routeItem.path}
						key={routeItem.id}
						element={<routeItem.component />}
					/>
				))}
			</Routes>
		</Suspense>
	);
}
