import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import '@fontsource/roboto';
import { Provider } from 'react-redux';
import Store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme({
  typography: {
    bodyTitle: {
      // fontSize: '26px', 
      color: '#414042', 
      display:'flex',
      textAlign:'center',
      fontFamily: 'Poppins, sans-serif',
      textTransform:"uppercase",
      '@media (min-width: 320px)': {
        fontSize: '16px', 
      },
      '@media (min-width: 1200px)': {
        fontSize: '26px', 
      },
    }, 
    bodyTitle2: {
      color: '#414042', 
      fontFamily: 'Poppins, sans-serif',
      fontWeight:'600',
      '@media (min-width: 320px)': {
        fontSize: '12px', 
      },
      '@media (min-width: 1200px)': {
        fontSize: '26px', 
      },
      // textTransform:"uppercase"

    }, 
    bodyTitle3: {
      color: 'white', 
      fontFamily: 'Poppins, sans-serif',
      fontWeight:'600',
      textTransform:'capitalize',
      '@media (min-width: 320px)': {
        fontSize: '12px', 
      },
      '@media (min-width: 1200px)': {
        fontSize: '14px', 
      },
      // textTransform:"uppercase"

    }, 
    bodyTitle4: {
      color: 'black', 
      fontFamily: 'Poppins, sans-serif',
      fontWeight:'600',
      textTransform:'capitalize',
      '@media (min-width: 320px)': {
        fontSize: '12px', 
      },
      '@media (min-width: 1200px)': {
        fontSize: '14px', 
      },
      // textTransform:"uppercase"

    }, 
    bodyTitle5: {
      color: 'black', 
      fontFamily: 'Poppins, sans-serif',
      fontWeight:'600',
      textTransform:'capitalize',
      '@media (min-width: 320px)': {
        fontSize: '12px', 
      },
      '@media (min-width: 1200px)': {
        fontSize: '14px', 
      },
      // textTransform:"uppercase"

    }, 
    bodyText: {
      color: '#414042', 
      fontFamily: 'Poppins, sans-serif',
      '@media (min-width: 320px)': {
        fontSize: '12px', 
      },
      '@media (min-width: 1200px)': {
        fontSize: '18px', 
      },
    }
  }
})
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme} >
    <Provider store={Store}>

      <App />
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
);
