import { configureStore } from '@reduxjs/toolkit';
import booleanSlice from './booleanSlice';
import rootReducer from './slice';
export default configureStore({
  reducer: {
    root:rootReducer,
    boolean:booleanSlice,
  },
});
// const Store = configureStore({
//   reducer: {
//     root: rootSlice,
//     middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
//   },
// });

// export default Store;